* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-image: url('assets/94261.jpg');
  background-repeat: no-repeat;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.quote-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  font-size: 30px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 600;
  align-items: center;
  width: 70%;
  background: rgba(255, 255, 255, 0.7);
  padding: 35px 20px;
}

.page-loading {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 45px;
}

.loading-icon {
  width: 150px;
  height: 100px;
}

.sad-img {
  width: 100px;
  height: 100px;
}

.double-quotes {
  font-size: 170px;
  font-family: 'Times New Roman', Times, serif;
  color: #000;
}

.quote-text {
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .quote-container {
    margin: 20px 0 100px 0;
  }

  .quote-text {
    font-size: 17px;
  }

  .page-loading {
    font-size: 31px;
    gap: 30px;
  }

  .quote-text span {
    font-size: 18px;
  }
}

.text-calc-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 70%;
  color: #000;
  background: rgba(255, 255, 255, 0.7);
}

.text-calc-container h2 {
  color: #000;
  font-size: 35px;
}

.calculator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input {
  width: 100%;
  height: 70px;
  font-size: 35px;
  background-color: rgb(133, 134, 148);
  border: 1px solid lightgray;
  padding: 10px;
  text-align: right;
  color: #fff;
}

.button-row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .text-calc-container {
    display: flex;
    flex-direction: column;
    width: 93%;
    margin-bottom: 100px;
  }

  .calculator {
    padding-bottom: 30px;
    margin-top: 34px;
  }
}

.main {
  display: flex;
  flex-direction: column;
  gap: 35px;
  align-items: center;
  width: 100%;
}

.quote-title {
  font-size: 35px;
  text-align: center;
}

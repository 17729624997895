.home-content {
  width: 85%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  padding: 30px;
  color: #000;
  margin-top: 40px;
  background-color: rgba(255, 255, 255, 0.8);
}

.home-content h2 {
  font-size: 32px;
  font-weight: 800;
}

.home-content p {
  text-align: justify;
  color: #000;
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .home-content {
    padding: 10px 30px;
    margin: 0;
    width: 90%;
    margin-bottom: 100px;
  }

  .home-content p {
    font-size: 18px;
  }

  .home-content h2 {
    font-size: 28px;
  }

  .second-para {
    display: none;
  }
}
